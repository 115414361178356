<template>
  <section>
    <v-row>
      <vue-dropzone ref="productImageDropzone" id="product-image-dropzone"
                    :options="dropzoneOptions" :useCustomSlot="true"
                    @vdropzone-success="uploadSuccess" @getUploadingFiles="onFileInfo"
                    @vdropzone-file-added="onFileInfo"
      >
        <div class="dropzone-custom-content">
          <h3 class="dropzone-custom-title">{{ $t('Drag and drop to upload content!') }}</h3>
          <div class="subtitle">{{ $t('...or click to select a file from your computer') }}</div>
        </div>
      </vue-dropzone>
    </v-row>
    <v-row>
      <draggable v-model="images">
        <transition-group tag="div" class="grid" name="grid">
          <product-edit-gallery-image v-for="image in images" :key="image.value_id" :image="image" @remove-image="removeImage" />
        </transition-group>
      </draggable>
    </v-row>
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import draggable from 'vuedraggable'
import ProductEditGalleryImage from '@/components/Catalog/ProductEditModal/ProductEditGalleryImage'
import { buildServerUrl } from '@/server/request'

export default {
  name: 'ProductEditGallery',
  components: {
    vueDropzone: vue2Dropzone,
    draggable,
    ProductEditGalleryImage
  },
  props: {
    productId: {
      type: Number,
      required: true,
      default: 0
    }
  },
  computed: {
    ...mapGetters({
      token: 'user/getAccessToken'
    }),
    dropzoneOptions () {
      return {
        url: buildServerUrl('catalog.product', 'upload_image').replace('{{product_id}}', this.productId),
        autoProcessQueue: !!this.productId,
        uploadMultiple: true,
        acceptedFiles: 'image/*',
        disablePreviews: true,
        headers: { 'Authorization': `Bearer ${this.token}` }
      }
    }
  },
  data () {
    return {
      image: null,
      images: []
    }
  },
  methods: {
    ...mapActions({
      loadProductImages: 'catalog/product/loadProductImages',
      removeProductImage: 'catalog/product/removeProductImage'
    }),
    onFileInfo (payload) {
      if (!this.productId) {
        this.images.push(payload)
        this.$emit('uploadImages', this.images)
      }
    },
    uploadSuccess (file, response) {
      this.images.push(...response.images)
    },
    removeImage (imageId) {
      this.removeProductImage({ token: this.token, product_id: this.productId, image_id: imageId })
        .then(() => {
          let imageIdx = this.images.findIndex(i => i.value_id === imageId)
          this.images.splice(imageIdx, 1)
          this.$refs.productImageDropzone.removeAllFiles(true)
        })
    }
  },
  mounted () {
    if (this.productId) {
      this.loadProductImages({token: this.token, product_id: this.productId})
        .then(result => {
          this.images = result.images
        })
    }
  }
}
</script>
<style scoped>
#product-image-dropzone {
  position: relative;
  margin-top: 25px;
  width: 100%;
}

.dropzone-custom-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.dropzone-custom-title {
  margin-top: 0;
  color: #00b782;
}

.subtitle {
  color: #314b5f;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 145px);
  grid-template-rows: auto;
  grid-gap: 0.2em;
}

.grid-move {
  transition: all 0.3s;
}
</style>
