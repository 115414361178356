<template>
  <v-col>
    <v-img :src="`${imageBaseUrl}${image.value}`" :lazy-src="`${imageBaseUrl}${image.value}`" aspect-ratio="1" class="grey lighten-2 image-hover">
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate color="grey lighten-5" />
        </v-row>
      </template>
      <div class="delete-button" @click="removeImage"><v-icon>mdi-delete</v-icon></div>
      <div class="image-action"><v-icon>mdi-arrow-split-vertical</v-icon></div>
    </v-img>
  </v-col>
</template>
<script>
export default {
  name: 'ProductEditGalleryImage',
  props: {
    image: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      imageBaseUrl: process.env.VUE_APP_BASE_IMAGE_URL + '/catalog/product'
    }
  },
  methods: {
    removeImage () {
      this.$emit('remove-image', this.image.value_id)
    }
  }
}
</script>
<style scoped>
image {
  display: flex;
  justify-content: center;
  align-items: center;
  position:relative;
}

.image-hover {
  cursor: move;
}

.delete-button {
  cursor: pointer;
}

.image-hover .delete-button, .image-hover .image-action {
  opacity: 0;
}

.image-hover:hover .delete-button {
  position: absolute;
  padding: 5px;
  right: 0px;
  left: auto;
  top: 0px;
  opacity: 1;
}

.image-hover:hover .image-action{
  position: absolute;
  bottom: 5px;
  width: 100%;
  opacity: 1;
  right: -40%;
}

</style>
