<template>
  <div class="ProductEditDialog">
    <v-dialog v-model="productEditModal" persistent width="700px" @keydown.esc="closeModal">
      <v-card>
        <v-card-title class="headline">
          {{ mode === 'new' ? $t('Create Product') : $t('Edit Product') }}
        </v-card-title>
        <v-card-text>
          <v-progress-linear v-if="loading" indeterminate color="blue" class="mb-0" />
          <v-container v-else>
            <form ref="productModalForm">
              <v-row>
                <v-col cols="12" sm="10">
                  <v-text-field :rules="name" v-model="product.name" :label="$t('Name *')" required />
                </v-col>
                <v-col cols="12" sm="2">
                  <v-switch v-model="product.status" color="orange" inset flat hide-details />
                </v-col>
                <v-col cols="12">
                  <v-text-field :disabled="mode !== 'new'" v-model="product.sku" :prefix="mode !== 'new' ? '' : `${partner.sku_prefix}-`" :rules="[v => v || $t('SKU is required')]" label="SKU *" required />
                </v-col>
                <v-col cols="12">
                  <label>{{ $t('Description') }}</label><br>
                  <tiptap-vuetify v-model="product.description" :extensions="extensions" :placeholder="$t('Product description...')"/>
                </v-col>
                <v-col cols="12">
                  <label>{{ $t('Short Description') }}</label><br>
                  <tiptap-vuetify v-model="product.short_description" :extensions="extensions" :placeholder="$t('Product short description...')"/>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="product.price" :rules="price" :label="$t('Price *')" type="decimal" prefix="₴" required />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="product.special_price" :label="$t('Special Price')" type="decimal" prefix="₴"
                                hint="if special price is set you will be able to set dates when it's active"
                  />
                </v-col>
                <template v-if="specialPriceDatesAllowed">
                  <v-col cols="12" sm="6">
                    <v-menu ref="menu_sfd" v-model="datePickers.menuSfd" :close-on-content-click="false"
                      transition="scale-transition" offset-y min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="product.special_from_date" :label="$t('Special From Date *')" :class="{'error-label': validateSpecialDates}"
                                      required append-icon="mdi-close" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" @click="onTab(0)" @click:append="flushSpecialFromDate"
                        />
                      </template>
                      <v-container class="compact-date-time">
                        <v-row class="compact-row">
                          <v-col>
                            <v-tabs v-model="tab" grow>
                              <v-tab @click="onTab(0)">Date</v-tab>
                              <v-tab @click="onTab(1)">Time</v-tab>
                            </v-tabs>
                          </v-col>
                        </v-row>
                        <v-row class="compact-row">
                          <v-col>
                            <v-date-picker v-model="specialDateFrom" v-show="tab === 0" :min="specialCurrentDate" class="compact-picker" />
                            <v-time-picker v-model="specialTimeFrom" v-show="tab === 1" :max="specialTimeMinMax.max" format="24hr" class="compact-picker" ></v-time-picker>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-menu ref="menu_std" v-model="datePickers.menuStd" :close-on-content-click="false"
                            transition="scale-transition" offset-y min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="product.special_to_date" :label="$t('Special To Date')" :class="{'error-label': validateSpecialDates}"
                                      append-icon="mdi-close" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" @click="onTab(0)" @click:append="flushSpecialToDate"
                        />
                      </template>
                      <v-container class="compact-date-time">
                        <v-row class="compact-row">
                          <v-col>
                            <v-tabs v-model="tab" grow>
                              <v-tab @click="onTab(0)">{{ $t('Date') }}</v-tab>
                              <v-tab @click="onTab(1)">{{ $t('Time') }}</v-tab>
                            </v-tabs>
                          </v-col>
                        </v-row>
                        <v-row class="compact-row">
                          <v-col>
                            <v-date-picker v-model="specialDateTo" v-show="tab === 0" :min="specialDateFrom" class="compact-picker" />
                            <v-time-picker v-model="specialTimeTo" v-show="tab === 1" :min="specialTimeMinMax.min" format="24hr" class="compact-picker" ></v-time-picker>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-menu>
                  </v-col>
                  <span v-if="validateSpecialDates" class="error-message">* {{ '"' + $t('Special To Date') + '"' + $t(' less than ') + '"' + $t('Special From Date') + '"' }}</span>
                </template>
                <v-col cols="12" sm="6">
                  <v-switch v-model="product.is_qty_decimal" :label="$t('is Qty in decimal')" color="orange" inset flat hide-details />
                </v-col>
                <v-col v-if="product.is_qty_decimal" cols="12" sm="6">
                  <v-select v-model="product.measure_type" :items="weightTypes" :label="$t('Measure Types')"
                            item-text="title" item-value="value" :rules="[v => !!v || $t('Measure type is required')]"
                            :hint="$t('Select measure')" persistent-hint
                  />
                </v-col>
                <div v-if="product.is_qty_decimal" style="width: 100%; display: flex; flex-wrap: wrap;">
                  <v-col cols="12" sm="6">
                    <v-text-field v-model="product.min_sale_qty" :rules="[v => (v && v > 0) || $t('Weight is required')]"
                                  :label="$t('Minimal Qty *')" type="decimal" required
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model="product.qty_increments" :label="$t('Qty increments *')"
                                  :rules="[v => (v && v > 0) || $t('Qty is required')]" type="decimal" required
                    />
                  </v-col>
                </div>
                <v-col cols="12" >
                  <v-select v-model="selectedOptions"  :items="availableOptions" :label="$t('Product Options')" multiple chips
                            item-text="name" item-value="id" :hint="$t('Select product options')" persistent-hint />
                </v-col>
                <v-col cols="12">
                  <treeselect v-model="selectedCategories" :class="{'required-tree-category': !selectedCategories.length}" :multiple="true" :options="categoriesTree" :normalizer="normalizer"
                              value-consists-of="ALL_WITH_INDETERMINATE"
                              :placeholder="$t('Product Category *')"
                  />
                  <span v-if="!selectedCategories.length" class="text-required">{{ $t('Category is required') }}</span>
                </v-col>
                <v-col cols="12">
                  <v-expansion-panels v-model="imagesExpanded" focusable>
                    <v-expansion-panel>
                      <v-expansion-panel-header>{{ $t('Images') }}</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <product-edit-gallery @uploadImages="saveImages" v-if="imagesExpanded === 0" :product-id="product.entity_id" ref="gallery" />
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </form>
          </v-container>
          <small>{{ $t('* indicates required field') }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="submit" :loading="saveInProgress" class="white--text mr-4 my-3" color="primary" depressed text>
            {{ $t('Save') }}
          </v-btn>
          <v-btn  @click="closeModal" class="mr-2 mt-0 white--text" color="red" depressed text>
            {{ $t('Close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ProductEditGallery from '@/components/Catalog/ProductEditModal/ProductEditGallery'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import {
  // component
  TiptapVuetify,
  // extensions
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from 'tiptap-vuetify'
import i18n from "@/plugins/i18n";

export default {
  name: 'ProductEditModal',
  components: {
    ProductEditGallery,
    Treeselect,
    TiptapVuetify
  },
  data () {
    return {
      tab: 0,
      specialCurrentDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      productEditModal: true,
      loading: false,
      saveInProgress: false,
      product: {},
      imagesExpanded: null,
      datePickers: {
        menuSfd: false,
        menuStd: false
      },
      selectedOptions: [],
      selectedCategories: [],
      availableOptions: [],
      extensions: [
        History,
        Blockquote,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, { options: { levels: [1, 2, 3] } }],
        Bold,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
      weightTypes: [
        {
          title: this.$t('Weight'),
          value: 'weight'
        },
        {
          title: this.$t('Volume'),
          value: 'volume'
        }
      ],
      name: [
        v => !!v || this.$t('Name is required'),
      ],
      price: [
        v => !!v || this.$t('Price is required'),
        v => (v && v > 0) || this.$t('Price cannot be equal to 0')
      ],
      images: []
    }
  },
  props: {
    productId: {
      type: Number,
      required: true
    },
    mode: {
      type: String,
      default: () => 'edit'
    },
    categoryId: {
      type: Number,
      required: false
    },
  },
  computed: {
    ...mapGetters({
      token: 'user/getAccessToken',
      categoriesTree: 'catalog/category/getTree',
      partner: 'user/getUserPartner'
    }),
    specialDateFrom: {
      get() {
        return this.product.special_from_date ? this.product.special_from_date.split(' ')[0] : ''
      },
      set(value) {
        this.tab = 1
        this.product.special_from_date = `${value} ${'00:00'}`
      },
    },
    specialTimeFrom: {
      get() {
        return this.product.special_from_date ? this.product.special_from_date.split(' ')[1] : ''
      },
      set(value) {
        this.product.special_from_date = `${this.specialDateFrom || (this.specialDateTo || this.specialCurrentDate)} ${value}`
      },
    },
    specialDateTo: {
      get() {
        return this.product.special_to_date ? this.product.special_to_date.split(' ')[0] : ''
      },
      set(value) {
        this.tab = 1
        this.product.special_to_date = `${value} ${'23:59'}`
      },
    },
    specialTimeTo: {
      get() {
        return this.product.special_to_date ? this.product.special_to_date.split(' ')[1] : ''
      },
      set(value) {
        this.product.special_to_date = `${this.specialDateTo || (this.specialDateFrom || this.specialCurrentDate)} ${value}`
      },
    },
    specialPriceDatesAllowed () {
      return this.product.special_price > 0 && parseFloat(this.product.special_price) < parseFloat(this.product.price)
    },
    specialTimeMinMax () {
      let sameDay = (this.specialDateFrom || this.specialDateTo) === (this.specialDateTo || this.specialDateFrom);
      let min = sameDay ? this.specialTimeFrom : null
      let max = sameDay ? this.specialTimeTo : null
      return { min, max }
    },
    validateSpecialDates () {
        return this.product.special_price && this.product.special_price > 0 && this.specialDateTo && this.specialDateFrom > this.specialDateTo
    }
  },
  methods: {
    ...mapActions({
      loadProduct: 'catalog/product/load',
      loadProductOptions: 'catalog/product/loadProductOptions',
      loadProductCategories: 'catalog/product/loadProductCategories',
      updateProduct: 'catalog/product/update',
      uploadImages: 'catalog/product/uploadImages',
      createProduct: 'catalog/product/create',
      loadTree: 'catalog/category/loadTree',
      loadOptions: 'catalog/product_option/getProductOptions'
    }),
    onTab(index) {
      this.tab = index
    },
    openDialog () {
      this.productEditModal = true
    },
    closeModal () {
      this.productEditModal = false
      this.product = {}
      this.imagesExpanded = null
      this.datePickers = { menuSfd: false, menuStd: false }
      this.toggleBodyScroll(false);
      this.$bus.$emit('catalog-product-edit', null)
      this.$bus.$emit('catalog-product-new', false)
    },
    submit () {
      if (!this.$refs.productModalForm.checkValidity()) return
      if (!this.selectedCategories.length) return
      if (this.validateSpecialDates) return
      if (this.mode === 'edit') {
        this.save()
      } else {
        this.create()
      }
    },
    save () {
      this.saveInProgress = true
      let product = this.product
      product.measure_type = this.weightType
      product.options = this.selectedOptions
      product.categories = this.selectedCategories
      if (this.$refs.gallery) {
        product.images = this.$refs.gallery.images
      }
      this.updateProduct({ token: this.token, product_id: this.product.entity_id, product })
        .then(result => {
          this.product = result.product
          this.product.price = Math.abs(this.product.price).toFixed(2)
          this.product.special_price = Math.abs(this.product.special_price).toFixed(2)
          this.product.status = this.product.status === 2 ? 0 : this.product.status
          this.selectedOptions = result.product.options
          this.selectedCategories = result.product.categories
          this.$bus.$emit('catalog-product-update', {product_id: this.product.entity_id, product: this.product})
        })
        .finally(() => {
          this.saveInProgress = false
          this.closeModal()
        })
    },
    flushSpecialFromDate() {
      this.product.special_from_date = null
    },
    flushSpecialToDate() {
      this.product.special_to_date = null
    },
    create () {
      this.saveInProgress = true
      let product = this.product
      product.measure_type = this.weightType
      product.options = this.selectedOptions
      product.categories = this.selectedCategories
      this.createProduct({ token: this.token, product })
        .then(res => {
          if (res.status === 'error') {
            this.$store.dispatch('notification/spawnNotification', {
              type: 'error',
              message: i18n.t(res.message),
              action1: { label: i18n.t('OK') }
            })
          } else if (this.partner.sku_prefix + '-' + product.sku !== res.product.sku) {
            this.$store.dispatch('notification/spawnNotification', {
              type: 'info',
              message: i18n.t('Product SKU was changed to ') + res.product.sku,
              action1: { label: i18n.t('OK') }
            })
          }
          this.product = res.product
          this.product.price = Math.abs(this.product.price).toFixed(2)
          this.product.special_price = Math.abs(this.product.special_price).toFixed(2)
          this.product.status = this.product.status === 2 ? 0 : this.product.status
          this.selectedOptions = res.product.options
          this.selectedCategories = res.product.categories
          if (this.images.length && res.status !== 'error') {
            this.uploadImages({token: this.token, productId: res.product.entity_id, images: this.images})
          }
          this.$emit('created', this.product)
          this.$bus.$emit('catalog-product-new', false)
        })
        .finally(() => {
          this.saveInProgress = false
          this.closeModal()
        })
    },
    normalizer(node) {
      return {
        id: node.entity_id,
        label: node.name,
        children: node.children,
      }
    },
    saveImages (images) {
      this.images = images
    },
    toggleBodyScroll(disable) {
      document.documentElement.style.overflowY = disable ? 'hidden' : 'auto';
    }
  },
  watch: {
    'product.special_price' () {
      if (!parseFloat(this.product['special_price'])) {
        this.product['special_from_date'] = null
        this.product['special_to_date'] = null
      }
    }
  },
  mounted () {
    this.loadTree(this.token)
    this.loadOptions({ token: this.token, params: { ipp: -1 }})
      .then(result => {
        this.availableOptions = result.data.data
      })
    if (this.mode === 'edit') {
      this.loading = true
      this.loadProduct({ token: this.token, product_id: this.productId })
        .then(result => {
          this.product = result.product
          this.weightType = this.product.measure_type ?? 'weight'
          this.product.price = Math.abs(this.product.price).toFixed(2)
          this.product.special_price = Math.abs(this.product.special_price).toFixed(2)
          this.product.status = this.product.status === 2 ? 0 : this.product.status
          return this.loadProductOptions({ token: this.token, product_id: this.productId })
            .then(optionsResult => {
              this.selectedOptions = optionsResult.options.map(o => o.id)
              return this.loadProductCategories({ token: this.token, product_id: this.productId })
                .then(categoriesResult => {
                  this.selectedCategories = categoriesResult.categories
                })
            })
        })
        .finally(() => {
          this.loading = false
        })
    } else {
      this.product = {
        sku: '',
        name: '',
        price: 0.0,
        special_price: 0.0,
        stock_qty: 1000.0000,
        is_qty_decimal: false,
        status: 1,
        categories: [],
        qty: 1
      }
      if (this.categoryId) {
        this.selectedCategories.push(this.categoryId)
      }
    }
    this.toggleBodyScroll(true)
  }
}
</script>
<style lang="scss" scoped>
  .text-required {
    font-size: 12px;
    color: #d32f2f;
  }
  .compact-date-time{
    background-color: white;
    width: 290px;
    overflow: hidden;
  }
  .compact-row {
    display: flex;
    align-items: center;
    margin: -20px;
  }
  .error-message {
    display: flex;
    color: #d32f2f;
    padding: 0px 40px;
    max-width: 100%;
    min-height: 14px;
    overflow: hidden;
    text-align: left;
    line-height: 12px;
  }

</style>
<style lang="scss">
  .compact-date-time{
    .v-time-picker-title {
      justify-content: center;
      .v-picker__title__btn {
        font-size: 50px;
      }
    }
  }
  .required-tree-category {
    .vue-treeselect__placeholder {
      color:#d32f2f;
    }
  }
  .error-label {
    .v-text-field__slot {
      label {
        color: #d32f2f;
        opacity: 1;
      }
      input {
        color: #d32f2f;
        opacity: 1;
      }
    }
  }
</style>
